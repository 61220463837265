import Vue from 'vue'
import axios from 'axios'
// import { Auth } from 'aws-amplify'

Vue.use({
    install(Vue) {
        //Configuracoes para chamadas gerais
        Vue.prototype.$http = axios
        

        //Configuracoes para chamada a AWS
        Vue.prototype.$aws = axios.create({
            baseURL: 'https://s4m79qhd0l.execute-api.us-east-1.amazonaws.com/sec/',
        });
        // Vue.prototype.$aws.interceptors.request.use(async (config) => {
        //     await Auth.currentSession().then(function(response){
        //         if (response.idToken) {
        //             config.headers.Authorization =  response.idToken.jwtToken;
        //         }
        //     }) 
        //     return config;
        // });
        // Vue.prototype.$aws.interceptors.response.use(undefined, function (error) {
        //     console.log('ERROR:')
        //     console.log(error)
        //     error.originalMessage = error.message
        //     Object.defineProperty(error, "message", { get: function () {
        //         console.log('ERROR.RESPONSE:')
        //         console.log(error.response)
        //         if (!error.response) {
        //             return error.originalMessage;
        //         }
        //         console.log('ERROR.RESPONSE.DATA:')
        //         console.log(error.response.data)
        //         return JSON.stringify(error.response.data);
        //     }});
        //     return Promise.reject(error);
        // });
        }
})


